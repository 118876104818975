import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import ModalForm from './ModalForm';
import Slider from 'react-slick';

const OpeningSlider = ({item}) => {
    const [logo, setLogo] = useState();
	const [show, setShow] = useState(false);
	const {localizationsItems} = useLangContext();

	const {
        items,
    } = useFetchItems(AppServices.settings)

	useEffect(() => {
        setLogo(items?.find(it => it.type === "logo").value)
	}, [items])

	const handleShow = () => {
		setShow(true);
	}
    
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: false,
        speed: 1200,
    };

  return (
    <Slider {...settings}>
        {item.elements?.map((card, index) =>
            <div className="banner" key={index}>
                <div className="banner-block banner-block-creative">
                    <div className="container-custom position-relative">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div className='logo-img'>
                                    <NavLink to={'/'}>
                                        <img src={logo} alt="" />
                                    </NavLink>
                                </div>
                                <div className="banner-content-creative">
                                    <h1 className="banner-title t-u">{card.title}</h1>
                                    <p className="lead lead-lg" dangerouslySetInnerHTML={{ __html: card.description }}></p>
                                    <div className='offer' dangerouslySetInnerHTML={{ __html: card.subtitle }}></div>
                                    <div className="banner-btn-creative">
                                        <div className="btn-creative btn-banner" onClick={handleShow}>{card.button_text}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-image">
                        <img src={card.back_image} alt="banner"/>
                    </div>
                    <div className='banner-after'>
                        <img src={card.front_image} alt="" />
                    </div>
                </div>
            </div>
        )}
        {show && <ModalForm show={show} setShow={setShow}/>}
    </Slider>
  )
}

export default OpeningSlider