import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import { useLangContext } from '../../i18n/ProvideLang';

const Form = ({item, dark}) => {
	const [loading, setLoading] = useState();
    const [visible, setVisible] = useState(false);
    const {localizationsItems} = useLangContext();

	const {handleSubmit, register, reset, formState: {errors}} = useForm();

	function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {}
        }

        for(var key in data){
            let temp = item.elements.find(it => it.name === key)
            if(temp){
                newData.fields[temp.label] = data[key]
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            getCookie('utm_source') && newData.append('utm_source', getCookie('utm_source'));
            getCookie('utm_medium') && newData.append('utm_medium', getCookie('utm_medium'));
            getCookie('utm_campaign') && newData.append('utm_campaign', getCookie('utm_campaign'));
            getCookie('utm_content') && newData.append('utm_content', getCookie('utm_content'));
            getCookie('utm_term') && newData.append('utm_term', getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
            console.log(res)
        }else{
            console.log(res)
        }
        setLoading(false)
    }

  return (
	<div className={dark ? "secdtion section-x tc-grey-alt form-section form-section-dark" : "secdtion section-x tc-grey-alt form-section form-light"}>
		<div className="container">
			<div className="row">
				<div className='col-md-12'>
					<div className="section-head">
						{item.subtitle && <h5 className="heading-xs dash">{item.subtitle}</h5>}
                        <h1 className='banner-heading'>{item.title}</h1>
						<p dangerouslySetInnerHTML={{ __html: item.description }}></p>
					</div>
				</div>
			</div>
			<div className="row gutter-vr-30px">
				<div className={loading ? "col-lg-8 form-hidden isLoading" :  "col-lg-8 form-hidden"}>
					<form className="genox-form mt-10" onSubmit={handleSubmit(CustomSubmit)}>
						{item.elements?.map((elem, index) =>
							<div className="row" key={index}>
								<div className="form-field col-md-6">
									<input {...register(elem.name)} name={elem.name} type="text" placeholder={elem.placeholder} className="input bdr-b required"/>
								</div>
							</div>
						)}
                        <div className="row">
							<div className={dark ? "banner-btn-creative col-md-12 btn-create-light" : "banner-btn-creative col-md-12"}>
								<button type="submit" className="btn-creative">{localizationsItems?.send}</button>
							</div>
						</div>
                        {dark ?
                        <div className={visible ? 'form-success-dark form-success-visible col-md-6' : 'form-success-dark'}>
                            <i className='fa fa-check-circle'></i>
                            <h1>{localizationsItems?.thanks}</h1>
                            <p>{localizationsItems?.success_request}</p>
                        </div>
                        :
                            <div className={visible ? 'form-success form-success-visible col-md-6' : 'form-success'}>
                                <i className='fa fa-check-circle'></i>
                                <h1>{localizationsItems?.thanks}</h1>
                                <p>{localizationsItems?.success_request}</p>
                            </div>
                        }
					</form>
				</div>
                <div className='col-lg-3 phone-wrapper'>
                    {item.items?.map((it, index) =>
                        <div className='phone-block' key={index}>
                            <p dangerouslySetInnerHTML={{ __html: it.description }}></p>
                        </div>
                    )}
                </div>
			</div>
		</div>
        <div className='form-img'>
            <img src={item.form_image} alt="" />
            <div className='form-gradient'></div>
        </div>
	</div>
  )
}

export default Form