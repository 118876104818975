import React from 'react'

const ReviewCard = ({card}) => {
  return (
    <div className='reviews-card'>
        <div className='description' dangerouslySetInnerHTML={{__html: card.description}}></div>
        <h3>{card.title}</h3>
        <div className='reviews-subtitle'>{card.subtitle}</div>
    </div>
  )
}

export default ReviewCard