import React, { useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import img1 from "../../images/modal.png";
import AppServices from '../../services/general/appServices';

const ReviewModal = ({show, setShow}) => {
    const {localizationsItems} = useLangContext();
    const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState();

	const {handleSubmit, register, reset, formState: {errors}} = useForm();

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
			...data
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            getCookie('utm_source') && newData.append('utm_source', getCookie('utm_source'));
            getCookie('utm_medium') && newData.append('utm_medium', getCookie('utm_medium'));
            getCookie('utm_campaign') && newData.append('utm_campaign', getCookie('utm_campaign'));
            getCookie('utm_content') && newData.append('utm_content', getCookie('utm_content'));
            getCookie('utm_term') && newData.append('utm_term', getCookie('utm_term'));
        }

        const res = await AppServices.request(newData);

        if(res.statusCode === 200){
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
            console.log(res)
        }else{
            console.log(res)
        }
        setLoading(false)
    }

    const handleClose = () => {
		setShow(false);
	}

  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header className="p-0">
            <button type="button" className="btn-close" onClick={handleClose}>
                <i className="fa-solid fa-xmark"></i>
            </button>
        </Modal.Header>
        <Modal.Body>
            <div className='modal-form'>
                <div className={loading ? 'form-content isLoading' : 'form-content'}>
                    <h1>{localizationsItems?.review_title}</h1>
                    <p>{localizationsItems?.review_content}</p>
                    <form onSubmit={handleSubmit(CustomSubmit)}>
                        <div className="form-field col-md-6">
                            <input {...register('name', {required: true})} name='name' type="text" placeholder={localizationsItems?.name} className="input bdr-b required"/>
                        </div>
                        <div className="form-field col-md-6">
                            <input {...register('home', {required: true})} name='home' type="text" placeholder={localizationsItems?.home} className="input bdr-b required"/>
                        </div>
                        <div className="form-field col-md-6">
                            <textarea {...register('review', {required: true})} name='review' type="text" placeholder={localizationsItems?.review} className="input bdr-dark required"/>
                        </div>
                        <div className="banner-btn-creative col-md-12">
                            <button type="submit" className="btn-creative">{localizationsItems?.send_review}</button>
                        </div>
                    </form>
                </div>
                <div className='modal-img'>
                    <img src={img1} alt="" />
                </div>
                <div className={visible ? 'form-success form-success-visible' : 'form-success'}>
                    <i className='fa fa-check-circle'></i>
                    <h1>{localizationsItems?.thanks}</h1>
                    <p>{localizationsItems?.success_request}</p>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default ReviewModal